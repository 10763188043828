<template>
  <div>
    <section id="question-1" v-bind:class="question1" v-if="currentQuestion == 1">
      <div class="wrap">
        <div  class="container ">
          <div class="row pt-5">
            <div class="col">
              <p class="intro-copy"><strong>Get started</strong> by picking your favorite meme below <br class="d-none d-md-block"/><span>(it determines the style of your email template)</span></p>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6 col-6">
              <img src="../assets/meme-drake.png" alt="drake" class="meme-image" @click="updateMemeImage">
              <div class="form-check text-center">
                <input class="form-check-input" type="radio" name="meme" id="meme-drake" value="drake" v-model="currentMeme" @click="updateMeme">
                <label class="form-check-label" for="meme-drake">
                  Drake
                </label>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6 col-6">
              <img src="../assets/meme-kombucha.png" alt="kombucha" class="meme-image" @click="updateMemeImage">
              <div class="form-check text-center">
                <input class="form-check-input" type="radio" name="meme" id="meme-kombucha" value="kombucha" v-model="currentMeme" @click="updateMeme">
                <label class="form-check-label" for="meme-kombucha">
                  Kombucha
                </label>
              </div>

            </div>
            <div class="col-lg-3 col-md-6 col-sm-6 col-6">
              <img src="../assets/meme-tuba.png" alt="tuba" class="meme-image" @click="updateMemeImage">
              <div class="form-check text-center">
                <input class="form-check-input" type="radio" name="meme" id="meme-tuba" value="tuba" v-model="currentMeme" @click="updateMeme">
                <label class="form-check-label" for="meme-tuba">
                  Tuba
                </label>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6 col-6">
              <img src="../assets/meme-adams.png" alt="adams" class="meme-image" @click="updateMemeImage">
              <div class="form-check text-center">
                <input class="form-check-input" type="radio" name="meme" id="meme-adams" value="adams" v-model="currentMeme" @click="updateMeme" >
                <label class="form-check-label" for="meme-adams">
                  Anthony Adams
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="question-2" v-bind:class="question2" v-if="currentQuestion == 2">
      <div class="wrap">
        <div class="container-sm">
          <div class="row d-block d-md-none ">
            <div class="col-12">
              <p class="mobile-question-count">Question {{currentQuestion-1}}/5</p>
            </div>
          </div>
        </div>
        <div class="container-sm tile">
          <div class="row justify-content-center">
            <div class="col-11 col-md-8">
              <h3>Where are you off to?</h3>
              <label class="error" v-if="!destination">{{ errorText }}</label>
              <input type="text" class="long" name="destination" v-model="destination" placeholder="The Grand Canyon, the beach, a remote island, the moon, etc..." required>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="question-3" v-bind:class="question3" v-if="currentQuestion == 3">
      <div class="wrap">
        <div class="container-sm">
          <div class="row d-block d-md-none ">
            <div class="col-12">
              <p class="mobile-question-count">Question {{currentQuestion-1}}/5</p>
            </div>
          </div>
        </div>
        <div class="container-sm tile">
          <div class="row justify-content-center">
            <div class="col-11 col-md-8">
              <h3>What will you be doing? (short and sweet)</h3>
              <label class="error" v-if="!activity">{{ errorText }}</label>
              <input type="text" class="long" name="activity" v-model="activity" placeholder="Hiking, dominating my kids at monopoly, lounging on the couch all week, etc..." required>

            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="question-4" v-bind:class="question4" v-if="currentQuestion == 4">
      <div class="wrap">
        <div class="container-sm">
          <div class="row d-block d-md-none ">
            <div class="col-12">
              <p class="mobile-question-count">{{currentQuestion-1}}</p>
            </div>
          </div>
        </div>
        <div class="container-sm tile">
          <div class="row justify-content-center">
            <div class="col-11 col-md-8">
              <h3>Who’s got your back while you’re out?<br class="d-none d-md-block"/> </h3>
              <p class="small">(Select one. We won’t email them or store their info.)</p>
              <div class="row contact-radio mb-3">
                <div class="col-12 col-md-4">
                  <input class="form-check-input" type="radio" name="contact" value="boss" v-model="currentContact" @click="updateContact">
                  <label class="form-check-label" for="contact-boss">
                    My boss
                  </label>
                </div>
                <div class="col-12 col-md-4">
                  <input class="form-check-input" type="radio" name="contact" value="colleague" v-model="currentContact" @click="updateContact">
                  <label class="form-check-label" for="contact-colleague">
                    My colleague
                  </label>
                </div>
                <div class="col-12 col-md-4">
                  <input class="form-check-input" type="radio" name="contact" value="support team" v-model="currentContact" @click="updateContact">
                  <label class="form-check-label" for="contact-support">
                    My support team
                  </label>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-md-6 mb-4 mb-md-0" v-if="currentContact != 'support team'">
                  <label for="contact_name">Their Name </label>
                  <input type="text" id="contact_name" class="long" name="contact_name" v-model="contact_name" placeholder="Name" required>
                  <label for="" class="error" v-if="!contact_name">{{ contact_name_error }}</label>
                </div>
                <div class="col-12 col-md-6">
                  <label for="contact_info">Contact Info </label>
                  <input type="text" class="long" name="contact_info" v-model="contact_info" placeholder="Contact Info" required>
                  <label class="error" v-if="!contact_info">{{ contact_info_error }}</label>
                </div>
              </div>


            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="question-5" v-bind:class="question5" v-if="currentQuestion == 5">
      <div class="wrap">
        <div class="container-sm">
          <div class="row d-block d-md-none ">
            <div class="col-12">
              <p class="mobile-question-count">Question {{currentQuestion-1}}/5</p>
            </div>
          </div>
        </div>
        <div class="container-sm tile">
          <div class="row justify-content-center">
            <div class="col-11 col-md-8">
              <h3>When are you unavailable?</h3>
              <div class="row">
                <div class="col-12 col-md-6">
                  <label for="datepicker-dateformat1">Start date</label>
                  <b-form-datepicker
                      id="datepicker-dateformat1"
                      :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit' }"
                      locale="en"
                      placeholder="Start date" v-model="date_start"></b-form-datepicker>
                  <label for="" class="error" v-if="!date_start">{{ date_start_error }}</label>
                </div>
                <div class="col-12 col-md-6 mb-4 mb-md-0">
                  <label for="datepicker-dateformat2">End date</label>
                  <b-form-datepicker
                      id="datepicker-dateformat2"
                      :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit' }"
                      locale="en"
                      placeholder="End date" v-model="date_end"></b-form-datepicker>
                  <label for="" class="error" v-if="!date_end || date_end_error">{{ date_end_error }}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="question-6" v-bind:class="question6" v-if="currentQuestion == 6">
      <div class="wrap">
        <div class="container-sm">
          <div class="row d-block d-md-none ">
            <div class="col-12">
              <p class="mobile-question-count">Question {{currentQuestion-1}}/5</p>
            </div>
          </div>
        </div>
        <div class="container-sm tile">
          <div class="row justify-content-center">
            <div class="col-11 col-md-8">
              <h3>Where should we send your custom OOO template? (email, please)</h3>
              <label for="" class="error" v-if="!email || !this.validEmail(this.email)">{{ email_error }}</label>
              <input type="email" class="long" name="email" v-model="email" placeholder="leave@mealone.net, Iamoutta@here.co, vacation@vibes.com," required>
            </div>
            <span>{{currentEmailTemplate}}</span>
          </div>
        </div>
      </div>
    </section>
    <section id="steps-nav" class="mt-4">
      <div class="container-sm">
        <div class="row">
          <div class="col-8 steps d-none d-md-block">
            <p style="margin-top: 1em;" v-if="currentQuestion == 1">Memes brought to you by <a href="http://www.thedailysales.net/" target="_blank">The Daily Sales</a></p>
            <ul v-if="currentQuestion > 1">
              <li v-bind:class="step2Status"><span></span>Question 1</li>
              <li v-bind:class="step3Status"><span></span>Question 2</li>
              <li v-bind:class="step4Status"><span></span>Question 3</li>
              <li v-bind:class="step5Status"><span></span>Question 4</li>
              <li v-bind:class="step6Status"><span></span>Question 5</li>
            </ul>
          </div>
          <div class="col-12 col-md-4 nav-items text-end">
            <div class="row">
              <div class="col-12 col-md-6 order-1 order-md-0">
                <button class="button secondary" v-if="currentQuestion > 1" @click="previousQuestion"><img src="../assets/icon-arrow-back.svg" alt=""> Back</button>
              </div>
              <div class="col-12 col-md-6 order-0 order-md-1">
                <button class="button primary " @click="nextQuestion">{{ next_question_text }} <img src="../assets/icon-arrow-next.svg" alt=""></button>
              </div>
            </div>


          </div>
        </div>
        <div class="row d-block" v-if="debug">
          <p>Checked meme: {{ currentMeme }}</p>
          <p>Destination: {{ destination }}</p>
          <p>What: {{ activity }}</p>
          <p>Start: {{ currentStartDate }}</p>
          <p>End: {{ currentEndDate }}</p>
          <p>Email: {{ email }}</p>
          <span>Contact: {{ contact_name }}</span>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: "FormBody",

  props: {
  },

  computed: {
    currentQuestion() {
      return this.$store.getters.currentQuestion;
    },
    currentMeme() {
      return this.$store.getters.currentMeme;
    },
    currentMemeImage() {
      return 'meme-' + this.$store.getters.currentMeme + '.png';
    },
    currentDestination() {
      return this.$store.getters.currentDestination;
    },
    currentActivity() {
      return this.$store.getters.currentActivity;
    },
    currentContact() {
      return this.$store.getters.currentContact;
    },
    currentContactName() {
      return this.$store.getters.currentContactName;
    },
    currentContactInfo() {
      return this.$store.getters.currentContactInfo;
    },
    currentStartDate() {
      return this.$store.getters.currentStartDate;
    },
    currentEndDate() {
      return this.$store.getters.currentEndDate;
    },
    currentEmail() {
      return this.$store.getters.currentEmail;
    },
    currentEmailTemplate() {
      return this.$store.getters.currentEmailTemplate;
    },

    currentMemeEmaildrake(){
      // Text is slightly different if we have no contact name. This is only when they choose Support Team
      if(this.$store.getters.currentContactName == ''){
        return '<p>My manager just laughed at me.</p><p>Literally.</p><p>Like LOL kinda laugh.</p><p>I let my boss know I’m taking some time off. I’m out. Out of my (virtual) office... out. Not checking email... out. Going to&nbsp;' + this.$store.getters.currentDestination +'... out.</p><p>You get the point.</p><p><strong>TL;DR: I’m out of the office. I won’t be checking email until I get back.</strong></p><p>FULL DEETS:</p><p>✅&nbsp;&nbsp;I’ll be out of the office (aka, OOO) from '+ this.$store.getters.currentStartDate +' to '+ this.$store.getters.currentEndDate +'.<br />✅&nbsp;&nbsp;I’m heading to '+ this.$store.getters.currentDestination +' where I’ll be '+ this.$store.getters.currentActivity +'.<br />✅&nbsp;&nbsp;While I’m out, my '+this.$store.getters.currentContact+' has my back. </p><p>They can be reached at the following:&nbsp;'+this.$store.getters.currentContactInfo+'</p><p>See ya on the flip side.</p>';
      }else{
        return '<p>My manager just laughed at me.</p><p>Literally.</p><p>Like LOL kinda laugh.</p><p>I let my boss know I’m taking some time off. I’m out. Out of my (virtual) office... out. Not checking email... out. Going to&nbsp;' + this.$store.getters.currentDestination +'... out.</p><p>You get the point.</p><p><strong>TL;DR: I’m out of the office. I won’t be checking email until I get back.</strong></p><p>FULL DEETS:</p><p>✅&nbsp;&nbsp;I’ll be out of the office (aka, OOO) from '+ this.$store.getters.currentStartDate +' to '+ this.$store.getters.currentEndDate +'.<br />✅&nbsp;&nbsp;I’m heading to '+ this.$store.getters.currentDestination +' where I’ll be '+ this.$store.getters.currentActivity +'.<br />✅&nbsp;&nbsp;While I’m out, my '+this.$store.getters.currentContact+', '+this.$store.getters.currentContactName+', has my back.</p><p>'+this.$store.getters.currentContactName+' can be reached at the following:&nbsp;'+this.$store.getters.currentContactInfo+'</p><p>See ya on the flip side.</p>';
      }
    },

    currentMemeEmailtuba(){
      // Text is slightly different if we have no contact name. This is only when they choose Support Team
      if(this.$store.getters.currentContactName == ''){
        return '<p>If you are reading this email now (which you are — think about it), I’m at chess camp.</p><p>Just kidding.</p><p>Or am I?</p><p>I mean, there’s nothing wrong with chess camp. The most-watched Netflix series of 2020 was The Queen’s Gambit which was about chess (and other things).</p><p>Either way, I’m pretty excited about my time off. <em>So</em> excited.</p><p>Like, SHOUTING-at-anyone-who-is-willing-to-listen… excited.</p><p>Even if some people aren’t really listening – that’s not stopping me!</p><p>Anyway...</p><p>FULL DEETS: </p><p>✅&nbsp;&nbsp;I’ll be out of the office (aka, OOO) from '+ this.$store.getters.currentStartDate +' to '+ this.$store.getters.currentEndDate +'.<br />✅&nbsp;&nbsp;I’m heading to '+ this.$store.getters.currentDestination +' where I’ll be '+ this.$store.getters.currentActivity +'.<br />✅&nbsp;&nbsp;While I’m out, my '+this.$store.getters.currentContact+' has my back.</p><p>They can be reached at the following:&nbsp;'+this.$store.getters.currentContactInfo+'</p><p>I’ll give you a SHOUT when I’m back.</p>';
      }else{
        return '<p>If you are reading this email now (which you are — think about it), I’m at chess camp.</p><p>Just kidding.</p><p>Or am I?</p><p>I mean, there’s nothing wrong with chess camp. The most-watched Netflix series of 2020 was The Queen’s Gambit which was about chess (and other things).</p><p>Either way, I’m pretty excited about my time off. <em>So</em> excited.</p><p>Like, SHOUTING-at-anyone-who-is-willing-to-listen… excited.</p><p>Even if some people aren’t really listening – that’s not stopping me!</p><p>Anyway...</p><p>FULL DEETS: </p><p>✅&nbsp;&nbsp;I’ll be out of the office (aka, OOO) from '+ this.$store.getters.currentStartDate +' to '+ this.$store.getters.currentEndDate +'.<br />✅&nbsp;&nbsp;I’m heading to '+ this.$store.getters.currentDestination +' where I’ll be '+ this.$store.getters.currentActivity +'.<br />✅&nbsp;&nbsp;While I’m out, my '+this.$store.getters.currentContact+', '+this.$store.getters.currentContactName+', has my back.</p><p>'+this.$store.getters.currentContactName+' can be reached at the following:&nbsp;'+this.$store.getters.currentContactInfo+'</p><p>I’ll give you a SHOUT when I’m back.</p>';
      }

    },

    currentMemeEmailadams(){
      if(this.$store.getters.currentContactName == ''){
        return '<p>Yeah... so... I’m uh... out of the office right now.</p><p>This is my OOO email.</p><p>You are reading it now.</p><p>I needed a bit of time off to refresh + recharge.</p><p>I can’t tell you where I’m going or when I’ll be back, but I can promise you it will be fun*.</p><p>*not all of this is true... keep scrolling</p><p>Anyway...</p><p>FULL DEETS:</p><p>✅&nbsp;&nbsp;I’ll be out of the office (aka, OOO) from '+ this.$store.getters.currentStartDate +' to '+ this.$store.getters.currentEndDate +'.<br />✅&nbsp;&nbsp;I’m heading to '+ this.$store.getters.currentDestination +' where I’ll be '+ this.$store.getters.currentActivity +'.<br />✅&nbsp;&nbsp;While I’m out, my '+this.$store.getters.currentContact+', has my back. </p><p>They can be reached at the following:&nbsp;'+this.$store.getters.currentContactInfo+'</p><p>See ya on the flip side (and with a full battery).</p>';
      }else{
        return '<p>Yeah... so... I’m uh... out of the office right now.</p><p>This is my OOO email.</p><p>You are reading it now.</p><p>I needed a bit of time off to refresh + recharge.</p><p>I can’t tell you where I’m going or when I’ll be back, but I can promise you it will be fun*.</p><p>*not all of this is true... keep scrolling</p><p>Anyway...</p><p>FULL DEETS:</p><p>✅&nbsp;&nbsp;I’ll be out of the office (aka, OOO) from '+ this.$store.getters.currentStartDate +' to '+ this.$store.getters.currentEndDate +'.<br />✅&nbsp;&nbsp;I’m heading to '+ this.$store.getters.currentDestination +' where I’ll be '+ this.$store.getters.currentActivity +'.<br />✅&nbsp;&nbsp;While I’m out, my '+this.$store.getters.currentContact+', '+this.$store.getters.currentContactName+', has my back.</p><p>'+this.$store.getters.currentContactName+' can be reached at the following:&nbsp;'+this.$store.getters.currentContactInfo+'</p><p>See ya on the flip side (and with a full battery).</p>';
      }
    },

    currentMemeEmailkombucha(){
      if(this.$store.getters.currentContactName == ''){
        return '<p>Ahhh. Sorry if my quick reply was misleading, making you think I was sitting in front of my laptop anxiously waiting to read, then answer your email.</p><p><img src="https://outofofficetemplates.com/img/false-hopes.jpg" alt="False hopes"></p><p>You see, I’m out of the office. Literally.</p><p><a href="https://media.tenor.com/images/f4c8059e75d21aa301174d4374ec4680/tenor.gif" target="_blank">Here’s me.</a></p><p><a href="https://ichef.bbci.co.uk/news/1024/cpsprodpb/EF0D/production/_102679116_outofoffice2.jpg" target="_blank">Here’s my office.</a></p><p>See, nowhere to be found.</p><p>But anyway...</p><p>Allow me to answer before you ask:</p><p>✅&nbsp;&nbsp;I’ll be out of the office (aka, OOO) from '+ this.$store.getters.currentStartDate +' to '+ this.$store.getters.currentEndDate +'.<br />✅&nbsp;&nbsp;I’m heading to '+ this.$store.getters.currentDestination +' where I’ll be '+ this.$store.getters.currentActivity +'.<br />✅&nbsp;&nbsp;While I’m out, my '+this.$store.getters.currentContact+', '+this.$store.getters.currentContactName+' has my back.</p><p>They can be reached at the following:&nbsp;'+this.$store.getters.currentContactInfo+'</p><p>Catch you when I’m back, for reals this time.</p>';
      }else{
        return '<p>Ahhh. Sorry if my quick reply was misleading, making you think I was sitting in front of my laptop anxiously waiting to read, then answer your email.</p><p><img src="https://outofofficetemplates.com/img/false-hopes.jpg" alt="False hopes"></p><p>You see, I’m out of the office. Literally.</p><p><a href="https://media.tenor.com/images/f4c8059e75d21aa301174d4374ec4680/tenor.gif" target="_blank">Here’s me.</a></p><p><a href="https://ichef.bbci.co.uk/news/1024/cpsprodpb/EF0D/production/_102679116_outofoffice2.jpg" target="_blank">Here’s my office.</a></p><p>See, nowhere to be found.</p><p>But anyway...</p><p>Allow me to answer before you ask:</p><p>✅&nbsp;&nbsp;I’ll be out of the office (aka, OOO) from '+ this.$store.getters.currentStartDate +' to '+ this.$store.getters.currentEndDate +'.<br />✅&nbsp;&nbsp;I’m heading to '+ this.$store.getters.currentDestination +' where I’ll be '+ this.$store.getters.currentActivity +'.<br />✅&nbsp;&nbsp;While I’m out, my '+this.$store.getters.currentContact+', '+this.$store.getters.currentContactName+', has my back.</p><p>'+this.$store.getters.currentContactName+' can be reached at the following:&nbsp;'+this.$store.getters.currentContactInfo+'</p><p>Catch you when I’m back, for reals this time.</p>';
      }
    },

    currentMemeEmailplans(){
      if(this.$store.getters.currentContactName == ''){
        return '<p>Fireworks. Baseball. Hot dogs.</p><p>I’m out of the office right now enjoying, playing, and eating the above.</p><p>While I’m out, I’m not checking email. I’m not passing Go. And I’m not collecting $200.</p><p>I’m celebrating Independence Day, commemorating the Declaration of Independence of the United States, on July 4, 1776.</p><p>FULL DEETS:</p><p>✅&nbsp;&nbsp;I’ll be out of the office (aka, OOO) from '+ this.$store.getters.currentStartDate +' to '+ this.$store.getters.currentEndDate +'.<br />✅&nbsp;&nbsp;I’m heading to '+ this.$store.getters.currentDestination +' where I’ll be '+ this.$store.getters.currentActivity +'.<br />✅&nbsp;&nbsp;While I’m out, my '+this.$store.getters.currentContact+', has my back. </p><p>They can be reached at the following:&nbsp;'+this.$store.getters.currentContactInfo+'</p><p>When I’m back “in” the office, I’ll reply to this email (and the 400 others I’ll likely have. Yeah, me!)</p>';
      }else{
        return '<p>Fireworks. Baseball. Hot dogs.</p><p>I’m out of the office right now enjoying, playing, and eating the above.</p><p>While I’m out, I’m not checking email. I’m not passing Go. And I’m not collecting $200.</p><p>I’m celebrating Independence Day, commemorating the Declaration of Independence of the United States, on July 4, 1776.</p><p>FULL DEETS:</p><p>✅&nbsp;&nbsp;I’ll be out of the office (aka, OOO) from '+ this.$store.getters.currentStartDate +' to '+ this.$store.getters.currentEndDate +'.<br />✅&nbsp;&nbsp;I’m heading to '+ this.$store.getters.currentDestination +' where I’ll be '+ this.$store.getters.currentActivity +'.<br />✅&nbsp;&nbsp;While I’m out, my '+this.$store.getters.currentContact+', '+this.$store.getters.currentContactName+', has my back.</p><p>'+this.$store.getters.currentContactName+' can be reached at the following:&nbsp;'+this.$store.getters.currentContactInfo+'</p><p>When I’m back “in” the office, I’ll reply to this email (and the 400 others I’ll likely have. Yeah, me!)</p>';
      }
    },

    currentMemeEmailpressure(){
      if(this.$store.getters.currentContactName == ''){
        return '<p><strong>4th of July Fun Fact #1:</strong> Presidents John Adams (#2), Thomas Jefferson (#3), and James Monroe (#5) all died on the 4th of July. Adams and Jefferson died in 1826 on the exact same day within hours of each other.</p><p><strong>4th of July Fun Fact #2:</strong> John Adams and Thomas Jefferson were the only two Declaration of Independence signers who later served as President of the United States.</p><p><strong>4th of July Fun Fact #3:</strong> I’m out of the office... not checking email. </p><p>FULL DEETS:</p><p>✅&nbsp;&nbsp;I’ll be out of the office (aka, OOO) from '+ this.$store.getters.currentStartDate +' to '+ this.$store.getters.currentEndDate +'.<br />✅&nbsp;&nbsp;I’m heading to '+ this.$store.getters.currentDestination +' where I’ll be '+ this.$store.getters.currentActivity +'.<br />✅&nbsp;&nbsp;While I’m out, my '+this.$store.getters.currentContact+', has my back.</p><p>They can be reached at the following:&nbsp;'+this.$store.getters.currentContactInfo+'</p><p>I’ll reply to your email(s) when I’m back “in” the office. Cool? Cool.</p>';
      }else{
        return '<p><strong>4th of July Fun Fact #1:</strong> Presidents John Adams (#2), Thomas Jefferson (#3), and James Monroe (#5) all died on the 4th of July. Adams and Jefferson died in 1826 on the exact same day within hours of each other.</p><p><strong>4th of July Fun Fact #2:</strong> John Adams and Thomas Jefferson were the only two Declaration of Independence signers who later served as President of the United States.</p><p><strong>4th of July Fun Fact #3:</strong> I’m out of the office... not checking email. </p><p>FULL DEETS:</p><p>✅&nbsp;&nbsp;I’ll be out of the office (aka, OOO) from '+ this.$store.getters.currentStartDate +' to '+ this.$store.getters.currentEndDate +'.<br />✅&nbsp;&nbsp;I’m heading to '+ this.$store.getters.currentDestination +' where I’ll be '+ this.$store.getters.currentActivity +'.<br />✅&nbsp;&nbsp;While I’m out, my '+this.$store.getters.currentContact+', '+this.$store.getters.currentContactName+' has my back.</p><p>'+this.$store.getters.currentContactName+' can be reached at the following:&nbsp;'+this.$store.getters.currentContactInfo+'</p><p>I’ll reply to your email(s) when I’m back “in” the office. Cool? Cool.</p>';
      }
    },

    currentGoogleDocFields(){
      return 'email='+encodeURIComponent(this.email)+'&meme_type='+this.currentMeme+'&destination='+encodeURIComponent(this.currentDestination)+'&activity='+encodeURIComponent(this.currentActivity)+'&contact='+encodeURIComponent(this.currentContact)+'&contact_name='+encodeURIComponent(this.currentContactName)+'&contact_info='+encodeURIComponent(this.currentContactInfo)+'&state_date='+this.currentStartDate+'&end_date='+this.currentEndDate;
    },

  },

  data(){
    return{
      debug: false,
      question1: 'd-block',
      question2: 'd-block',
      question3: 'd-block',
      question4: 'd-block',
      question5: 'd-block',
      question6: 'd-block',
      next_question_text: 'Next',
      step2Status: 'active',
      step3Status: '',
      step4Status: '',
      step5Status: '',
      step6Status: '',
      destination: '',
      activity: '',
      contact: 'boss',
      contact_name: '',
      contact_name_error: '',
      contact_info: '',
      contact_info_error: '',
      date_start: '',
      date_start_error: '',
      date_end: '',
      date_end_error: '',
      email: '',
      email_error: '',
      errorText: '',
      emailTemplate: '',
    }
  },

  methods:{
    nextQuestion(){
      switch (this.currentQuestion){
        case 1:
          this.$store.dispatch('addQuestionCount');
          this.next_question_text = "Next Question";
          break
        case 2:
          if(this.destination != ''){
            this.$store.dispatch('addQuestionCount');
            this.$store.commit('storeCurrentDestination', this.sanitize(this.destination));
            this.errorText = '';
            this.step2Status = 'done';
            this.step3Status = 'active';
          }else{
            this.errorText = 'Complete the following field';
          }
          break;
        case 3:
          if(this.activity != ''){
            this.$store.dispatch('addQuestionCount');
            this.$store.commit('storeCurrentActivity', this.sanitize(this.activity));
            this.errorText = '';
            this.step3Status = 'done';
            this.step4Status = 'active';
          }else{
            this.errorText = 'Complete the following field';
          }
          break;
        case 4:
          if(this.currentContact == 'support team'){
            if(this.contact_info != ''){
              this.$store.dispatch('addQuestionCount');
              this.$store.commit('storeCurrentContactInfo', this.sanitize(this.contact_info));
              this.contact_name_error = '';
              this.contact_info_error = '';
              this.step4Status = 'done';
              this.step5Status = 'active';
            }else{
              if(this.contact_info == ''){
                this.contact_info_error = 'Complete the following field';
              }
            }
          }else{
            if(this.contact_name != '' && this.contact_info != ''){
              this.$store.dispatch('addQuestionCount');
              this.$store.commit('storeCurrentContactName', this.sanitize(this.contact_name));
              this.$store.commit('storeCurrentContactInfo', this.sanitize(this.contact_info));
              this.contact_name_error = '';
              this.contact_info_error = '';
              this.step4Status = 'done';
              this.step5Status = 'active';
            }else{
              if(this.contact_name == ''){
                this.contact_name_error = 'Complete the following field';
              }
              if(this.contact_info == ''){
                this.contact_info_error = 'Complete the following field';
              }
            }
          }

          break;
        case 5:
          if(this.date_start != '' && this.date_end != ''){
            this.compareStart = new Date(this.date_start);
            this.compareEnd = new Date(this.date_end);
            this.start_year = this.date_start.substring(0, 4);
            this.end_year = this.date_end.substring(0, 4);
            // Check for start and end dates
            if(this.compareStart > this.compareEnd){
              this.date_end_error = 'Make sure your ending date comes after start date';
            }else{
              this.$store.dispatch('addQuestionCount');
              this.$store.commit('storeCurrentStartDate', this.date_start.substring(5)+'-'+this.start_year);
              this.$store.commit('storeCurrentEndDate', this.date_end.substring(5)+'-'+this.end_year);
              this.date_start_error = '';
              this.date_end_error = '';
              this.step5Status = 'done';
              this.step6Status = 'active';
              this.next_question_text = 'Submit';
            }

          }else{
            if(this.date_start == ''){
              this.date_start_error = 'Add a start date';
            }
            if(this.date_end == ''){
              this.date_end_error = 'Add an end date';
            }
          }
          break;
        case 6:
          if(this.email != '' && this.validEmail(this.email)){
            // Update current template to send to Marketo
            this.setCurrentEmailTemplate();
            let ref = this
            this.$store.commit('storeCurrentEmail', this.email);
            // submit to marketo
            axios.post('https://www.gong.io/wp-json/gong/outofoffice', {
              email: this.email,
              outofOfficeEmail: this.emailTemplate,
              googleDoc: this.currentGoogleDocFields,
            })
            .then(function (response) {
              if(ref.debug){
                console.log(response);
              }
            })
            .catch(function (error) {
              console.log(error);
            });
            // Go to Thank-you page
            ref.$router.push({ path: '/thank-you' });

          }else{
            this.email_error = 'Please enter a valid email address';
          }
          break
      }

    },


    // Nav to previous question section
    previousQuestion(){
      switch (this.currentQuestion){
        case 2:
          this.$store.dispatch('subtractQuestionCount');
          this.next_question_text = "Next";
          this.errorText = '';
          this.step2Status = '';
          break;
        case 3:
          this.$store.dispatch('subtractQuestionCount');
          this.errorText = '';
          this.step2Status = 'active';
          this.step3Status = '';
          break;
        case 4:
          this.$store.dispatch('subtractQuestionCount');
          this.errorText = '';
          this.step3Status = 'active';
          this.step4Status = '';
          break;
        case 5:
          this.$store.dispatch('subtractQuestionCount');
          this.errorText = '';
          this.step4Status = 'active';
          this.step5Status = '';
          break;
        case 6:
          this.$store.dispatch('subtractQuestionCount');
          this.errorText = '';
          this.step5Status = 'active';
          this.step6Status = '';
          this.next_question_text = 'Next Question';
          break;
      }
    },

    // Update meme image in store
    updateMeme(event){
      console.log("meme: ");
      this.$store.commit('storeCurrentMeme', event.target.value);
    },

    // Update meme image in store
    updateMemeImage(event){
      this.$store.commit('storeCurrentMeme', event.target.alt);
    },

    // Update contact title in store
    updateContact(event){
      if(event.target.value == 'support team'){
        document.getElementById("contact_name").required = false;
      }
      this.$store.commit('storeCurrentContact', event.target.value);

    },

    // Validate email in step 6
    validEmail(email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },

    sanitize(string) {
      const map = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#x27;',
        "/": '&#x2F;',
      };
      const reg = /[&<>"'/]/ig;
      return string.replace(reg, (match)=>(map[match]));
    },

    // Set current email template to the current meme choice.
    setCurrentEmailTemplate(){
      switch (this.currentMeme){
        case 'drake':
          this.emailTemplate = this.currentMemeEmaildrake;
          this.$store.commit('storeCurrentEmailTemplate', this.currentMemeEmaildrake);
          break
        case 'kombucha':
          this.emailTemplate = this.currentMemeEmailkombucha;
          this.$store.commit('storeCurrentEmailTemplate', this.currentMemeEmailkombucha);
          break
        case 'tuba':
          this.emailTemplate = this.currentMemeEmailtuba;
          this.$store.commit('storeCurrentEmailTemplate', this.currentMemeEmailtuba);
          break
        case 'adams':
          this.emailTemplate = this.currentMemeEmailadams;
          this.$store.commit('storeCurrentEmailTemplate', this.currentMemeEmailadams);
          break
        case 'pressure':
          this.emailTemplate = this.currentMemeEmailpressure;
          this.$store.commit('storeCurrentEmailTemplate', this.currentMemeEmailpressure);
          break
        case 'plans':
          this.emailTemplate = this.currentMemeEmailplans;
          this.$store.commit('storeCurrentEmailTemplate', this.currentMemeEmailplans);
          break
      }
    },

  },

};
</script>

<style scoped lang="scss">

.container{
  max-width: 1240px;
}

.container-sm{
  max-width: 1040px;
}

.mobile-question-count{
  color: white;
}

.wrap{
  @media (max-width: 1060px) {
    margin-left: 1em;
    margin-right: 1em;
  }
}

#question-2, #question-3, #question-4, #question-5, #question-5, #question-6{
  .wrap{
    margin-top: -8em;
  }
}

#steps-nav{
  margin-bottom: 8em;
}

.meme-image{
  width: 100%;
  box-shadow: 0px 0px 28px rgba(50, 21, 89, 0.16);
  border-radius: 20px;
  cursor: pointer;
}

.steps{
  ul{
    margin-top: 1em;
    padding: 0;
    list-style-type: none;
    li{
      display: inline-block;
      margin-right: 20px;
      color: #5B5B5B;
      span{
        margin-bottom: 0.5em;
        display: block;
        width: 96px;
        height: 8px;
        border-radius: 30px;
        background-color: #DADADA;
      }

      &.done{
        span{
          background-color: #812AC1;
        }
      }
      &.active{
        color: #812AC1;
        font-weight: 700;
        position: relative;
        span{
          &:before{
            position: absolute;
            top: 0;
            left: 0;
            content: "";
            background-color: #812AC1;
            width: 80%;
            height: 8px;
            border-radius: 30px;
          }
        }
      }
    }
  }
}

.tile{
  padding: 4em 0;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0px 0px 28px rgba(50, 21, 89, 0.08);
  border: 1px solid #D9D9D9;

  &.email-body{
    padding: 1em 12px;
  }
}

.nav-items{
  padding-top: 1em;

  > button:first-child{
    margin-right: 20px;
  }
}

.button{
  color: #321559;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 1.2em;
  font-weight: 700;
  border: transparent;
  height: 32px;
  padding: 10px 18px 8px;
  border-radius: 36px;
  display: inline-flex;
  justify-content: center; /* center the content horizontally */
  align-items: center; /* center the content vertically */
  position: relative;

  &.primary{
    background-color: #F7CD05;
    //padding-right: 30px;

    img{
      margin-left: 0.5em;
    }


    &:hover{
      background-color: #ac9416;
    }
  }

  &.secondary{
    background-color: transparent;
    padding-left: 30px;

    img{
      margin-right: 0.5em;
    }

    &:hover{
      background-color: #c8c5ce;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 2em;
    line-height: 17px;
  }
}

.b-form-datepicker{
  border-radius: 32px;
  background-color:white !important;
  padding: 10px !important;
  border: 1px solid #321559;
}

#question-1{
  label{
    font-size: 20px;
    text-transform: none;
  }

  .intro-copy{
    font-size: 24px;
    color: #321559;
    text-align: center;
    margin-bottom: 1.5em;

    span{
      font-size: 18px;
    }
  }
}

label{
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 0.5em;

  &.error{
    color: red;
  }
}

.contact-radio{
  label{
    font-size: 20px;
    text-transform: none;
    margin-left: .75em;
  }
}

.form-check .form-check-input{
  float:none;
}

.form-check label{
  margin-left: 10px;
}

.meme-image{
  margin-bottom: 1.5em;
}

h3{
  font-size: 24px;
  margin-bottom: 1em;
  color: #321559;
}

input.long{
  width: 100%;
  border-radius: 36px;
  border: 1px solid #321559;
  padding: 15px 20px;

  &:focus{
    outline: none;
  }
}
</style>