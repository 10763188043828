import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    HeroH1: 'Out of Office Email Generator',
    HeroH2: 'Your personalized Out of Office email, compliments of Gong',
    HeroSubhead: 'Props for taking some time off.<br /> Now it’s time to tell everyone you’re unavailable in a memorable way. <strong>Warning:</strong> These templates are anything but boring (but completely safe for work).',
    currentQuestion: 1,
    currentMeme: 'drake',
    currentDestination: '',
    currentActivity: '',
    currentContact: 'boss',
    currentContactName: '',
    currentContactInfo: '',
    currentStartDate: '',
    currentEndDate: '',
    currentEmail: '',
    currentEmailTemplate: '',
  },

  getters: {
    currentQuestion: state => state.currentQuestion,
    currentMeme: state => state.currentMeme,
    currentDestination: state => state.currentDestination,
    currentActivity: state => state.currentActivity,
    currentContact: state => state.currentContact,
    currentContactInfo: state => state.currentContactInfo,
    currentContactName: state => state.currentContactName,
    currentStartDate: state => state.currentStartDate,
    currentEndDate: state => state.currentEndDate,
    currentEmail: state => state.currentEmail,
    currentEmailTemplate: state => state.currentEmailTemplate,
  },
  mutations: {
    updateSubhead (state, payload){
      state.HeroSubhead += payload.text;
    },
    incrementQuestion (state){
      state.currentQuestion++;
    },
    decrementQuestion (state){
      state.currentQuestion--;
    },
    storeCurrentMeme (state, payload){
      state.currentMeme = payload;
    },
    storeCurrentDestination (state, payload){
      state.currentDestination = payload;
    },
    storeCurrentActivity (state, payload){
      state.currentActivity = payload;
    },
    storeCurrentContact (state, payload){
      state.currentContact = payload;
    },
    storeCurrentContactName (state, payload){
      state.currentContactName = payload;
    },
    storeCurrentContactInfo (state, payload){
      state.currentContactInfo = payload;
    },
    storeCurrentStartDate (state, payload){
      state.currentStartDate = payload;
    },
    storeCurrentEndDate (state, payload){
      state.currentEndDate = payload;
    },
    storeCurrentEmail (state, payload){
      state.currentEmail = payload;
    },
    storeCurrentEmailTemplate (state, payload){
      state.currentEmailTemplate = payload;
    },
    resetStore (state){
      state.currentQuestion = 1;
      state.currentMeme = 'drake';
      state.currentDestination = '';
      state.currentActivity = '';
      state.currentContact = 'boss';
      state.currentContactName = '';
      state.currentContactInfo = '';
      state.currentStartDate = '';
      state.currentEndDate = '';
      state.currentEmail = '';
      state.currentEmailTemplate = '';
    },
  },
  actions: {
    addQuestionCount (context) {
      context.commit('incrementQuestion');
    },
    subtractQuestionCount (context) {
      context.commit('decrementQuestion');
    },
    updateMeme (context, payload) {
      console.log("updateMeme");
      context.commit("storeCurrentMeme", payload);
    },
  },
  modules: {},
});
