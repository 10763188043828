<template>
  <div class="home">
    <Hero :h1=HeroH1 :subhead=HeroSubhead />
    <FormBody />
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Footer from "@/components/Footer.vue";
import Hero from "@/components/Hero.vue";
import FormBody from "@/components/FormBody.vue";

export default {
  name: "Home",

  components: {
    FormBody,
    Footer,
    Hero,
  },


  data() {
    return {
      hello: 'welcome',
      HeroH1: this.$store.state.HeroH1,
      HeroSubhead: this.$store.state.HeroSubhead,
      meme: '',
    };
  },

  methods: {},
};
</script>
