<template>
  <section id="footer">
    <div class="container">
      <div class="row text-center">
        <div class="col-12 col-lg-1">
          <a href="https://www.gong.io/" target="_blank"><img alt="Gong.io" src="../assets/logo-gong-sm.svg" class="img-fluid footer-logo " /></a>
        </div>
        <div class="col-12 col-md-9">
          <p class="copy">&copy; {{currentYear}} Gong.io, San Francisco, CA. All Rights Reserved. <br class="d-block d-md-none"/><span class="">&nbsp;</span> <a href="https://www.gong.io/privacy-policy/" target="_blank">Privacy Policy</a>  | <a href="https://www.gong.io/terms-and-conditions/" target="_blank">Terms and Conditions</a>  |
            <a href="https://status.gong.io/?_ga=2.107007108.702635657.1620773212-1922720430.1619456745" target="_blank">Service Status</a></p>
        </div>
        <div class="col-12 col-md-2">
          <p class="social">
            <a href="https://www.facebook.com/To.GongIO/" target="_blank"><img alt="Gong on Facebook" src="../assets/icon-facebook.svg" /></a>
            <a href="https://twitter.com/gong_io" target="_blank"><img alt="Gong on Facebook" src="../assets/icon-twitter.svg" /></a>
            <a href="https://www.linkedin.com/company/gong-io" target="_blank"><img src="../assets/icon-linkedin.svg" alt="Gong on Linkedin"></a>
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Footer",

  computed: {
    currentYear (){
      return new Date().getFullYear();
    }
  },
};
</script>

<style scoped lang="scss">
.container{
  max-width: 1240px;
}

.container-sm{
  max-width: 1040px;
}

#footer{
  margin-top: 3em;
  padding: 36px 0;
  background-color: #321559;
  color: white;

  .footer-logo{
    max-width:100px;
    margin-top:7px;
  }

  p.copy{
    margin-top: 1em;
    text-align: center;
    span{
      margin: 0 1em;
      position:relative;
      &:after{
        content: "";
        position: absolute;
        height: 32px;
        width: 1px;
        top: -40%;
        background-color: #666666;
      }
    }
    a{
      color:white;
      text-decoration: none;
      margin: 0 0.5em;
    }

    @media (max-width: 1040px) {
      font-size: 0.8rem;
      span{
        display: none;
      }
    }

    @media (max-width: 768px) {
      font-size: 1rem;

    }
  }
  p.social{
    margin-top: 0.75em;
    a{
      display: inline-block;
      margin-right: 0.5em;
    }
  }
}
</style>
