<template>
  <section id="hero">
    <div class="container">
      <div class="row">
        <div class="col">
          <a href="https://www.gong.io/"><img alt="Gong.io" src="../assets/logo-gong-sm.svg" class="hero-logo"/></a>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <h1 v-if="h1">{{ h1 }}</h1>
          <h2 v-if="h2">{{ h2 }}</h2>
          <p v-if="currentQuestion == 1" class="subhead" v-html="subhead"></p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Hero",
  props: {
    h1: String,
    h2: String,
    subhead: String,
  },

  computed: {
    currentQuestion() {
      return this.$store.getters.currentQuestion
    }
  },
};
</script>

<style scoped lang="scss">
  #hero{
    padding: 6em 0 4em;
    background-image: url("../assets/hero-bg.svg");
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 468px;
  }
  .hero-logo{
    width: 128px;
  }
  h1{
    font-size: 60px;
    font-weight: 600;
    color:white;
    text-align: center;
  }
  h2{
    font-size: 32px;
    max-width: 680px;
    margin: 1em auto;
    line-height: 44px;
    font-weight: 400;
    color:white;
    text-align: center;
  }
  p.subhead{
    font-size: 24px;
    margin: 1em auto;
    max-width: 730px;
    font-weight: 400;
    text-align: center;
    color:white;
    strong{
      font-weight: 700;
    }
  }
  @media (max-width: 768px) {
    #hero{
      padding: 3em 0;
      min-height: 390px;
    }
    h1{
      margin-top: 1em;
      font-size: 32px;
      text-align: left;
    }
    .hero-logo{
      width: 100px;
    }

    p.subhead, h2{
      font-size: 20px;
    }

    p.subhead{
      text-align: left;
    }

  }
</style>